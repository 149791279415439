.issue_bg {
    background-color: var(--goldenrod);
    /* height: 50vh; */
    height: fit-content;
    padding-bottom: 10rem;
    position: relative;
    overflow: hidden;
}
.header {
    width: 50%;
    margin: auto;
}
.issue_bg .header h2 {
    margin: 0;
    padding-top: 6rem;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 4.5px;
    color: var(--dark-brown);
}

.issue_bg .header p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-brown);
    width: 80%;
    margin: auto;
    padding: 4rem 0;
}

/*  */
.footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 7rem 1fr;
    /* margin-bottom: 15rem; */
    margin-top: 1rem;
}

.footer p {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.67;
    text-align: center;
    color: #fff;
}

.item_1 {
    grid-column: 1/2;
    grid-row: 1/3;
    border: solid 3px var(--black-two);
    background-color: var(--bright-orange-two);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 3rem 5rem; */
    position: relative;
}
.item_1 .coin {
    position: absolute;
    bottom: -10rem;
    right: 1rem;
    width: 10rem;
}

.item_1 h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: 55px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-brown);
}

.item_1 img {
    width: 10rem;
    height: 10rem;
    margin: 5rem auto;
    object-fit: contain;
}
.item_2 {
    grid-column: 2/3;
    grid-row: 1/2;
    background-color: var(--black-two);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px var(--black-two);
}

.item_3 {
    grid-column: 2/3;
    grid-row: 2/3;
}
.item_3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item_4 {
    grid-column: 3/4;
    grid-row: 1/2;
    border: solid 3px var(--black-two);
    background-color: var(--bright-orange-two);
    display: flex;
    justify-content: center;
    align-items: center;
}

.item_5 {
    grid-row: 2/3;
    position: relative;
}

.item_5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item_5 .lol {
    position: absolute;
    bottom: -4rem;
    right: 1rem;
    width: 8rem;
    height: 8rem;
}
.item_6 {
    grid-column: 4/5;
    grid-row: 1/2;
    background-color: var(--black-two);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px var(--black-two);
}
.item_7 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*  */
.dive_1 {
    position: absolute;
    rotate: 25deg;
    top: 1rem;
    right: 1rem;
}
.dive_2 {
    position: absolute;
    rotate: 25deg;
    top: 10rem;
    right: 1rem;
}
@media screen and (max-width: 1000px) {
    .item_4 p,
    .item_6 p,
    .item_2 p {
        font-size: 2rem;
    }
    .item_3 img,
    .item_5 img,
    .item_7 img {
        /* width: ; */
        height: 15rem;
    }
    .item_1 h3 {
        font-size: 2rem;
    }
    .item_1 img {
        height: 7rem;
    }
    
    .item_1 h3 {
        /* height: 10px; */
        display: none;
    }
}
