.footer {
    padding: 1rem 2rem;
    display: block;
}

.footer_up {
    /* grid-template-columns: 1fr 4fr; */
    display: flex;
    justify-content: space-between;
}

.footer_up .up_left {
    width: 20%;
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    font-size: 1rem;
    color: var(--black-two);
}
.footer_up .up_left h4 {
    font-weight: bold;
}
.footer_up .up_left p {
    font-weight: 300;
}
.footer_up .up_right {
    display: flex;
    align-items: flex-start;
    /* justify-content: flex-start; */
}

.footer_up .up_right table td,
th {
    /* border: 1px solid #999; */
    padding: 0.4rem 2rem;
    text-align: left;
    /* height: 4rem; */
}

/*  */
.footer_bot {
    display: flex;
    justify-content: space-between;
    padding: 4rem 0;
}
.footer_bot .bot_left {
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--greyish);
}

.footer_bot .bot_right img {
    margin-left: 1rem;
}
@media screen and (max-width: 1000px) {
    .footer_up .up_left {
        display: none;
    }
    .footer_up {
        justify-content: center;
    }
    .footer_up .up_right {
        align-items: center;
    }
}
