.box {
    /* width: 1920px; */

    height: 80px;
    /* padding: 24px 120px 27px 97px; */
    padding: 1rem 2rem;
    background-color: var(--black-two);
    color: blue;
    display: grid;
    grid-template-columns: 1fr 8fr;
    align-items: center;
    overflow: hidden;
}
.right {
    /* justify-content: end; */
    justify-self: end;
}
img.logo {
    /* width: 226px; */
    height: 2rem;
    /* margin: 3px 687px 0 0; */
    object-fit: contain;

    /* background-color: #fff; */
}

a {
    height: 0.8rem;
    margin: 0.8rem;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.introduction {
    /* width: 111px; */
}
.solution {
    /* width: 73px; */
    /* margin: 10px 72px 8px 76px; */
}

.plan {
    /* width: 78px; */
    /* margin: 10px 57px 8px 72px; */
}
.rectangle {
    width: 0.1rem;
    background-color: var(--pinkish-grey-two);
}
.login {
    /* width: 44px; */
}
.apply {
    /* width: 155px; */
}
@media screen and (max-width: 1000px) {
    .left {
        display: none;
    }
    .box {
        grid-template-columns: 1fr;
    }
    .right {
        /* grid-column: 1/ 3; */
        justify-self: center;
    }
}
