.list_product_bg {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 80%;
    margin: 10vh auto 0;
    column-gap: 1rem;
    row-gap: 1rem;
}
.product {
    /* width: 300px; */
    /* height: 300px; */

    /* background-color: var(--mango); */
}
.banner {
    font-size: 1.1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-three);
}
.banner p {
    color: var(--black-three);
    font-size: 2rem;
    margin: 0;
    padding: 0.5rem 0;
}
.detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.detail p {
    font-size: 1.5rem;
}
.pumpkin i {
    font-size: 1.5rem;
    color: var(--pumpkin-orange);
}

.pumpkin span {
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: left;
    color: var(--brownish-grey);
}

@media screen and (max-width: 1600px) {
    .list_product_bg {
        grid-template-columns: repeat(3, 1fr);
    }
    .banner p {
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 1000px) {
    .list_product_bg {
        grid-template-columns: repeat(2, 1fr);
    }
     .banner p {
        font-size: 1rem;
    }
}
