.happen_bg {
    display: flex;
    height: 50vh;
    color: #fff;
    background: url("./introduction-bg.png");
    background-position: center;
    background-size: cover;
    text-align: left;
    align-items: center;
}

.details {
    width: 20%;
    margin: 0 0 0 10rem;
}
.happen_bg .details h3 {
    font-size: 3.1rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 5px;
}

.happen_bg .details p {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
}

@media screen and (max-width: 1600px) {
    .details {
        width: 80%;
        margin: auto;
    }
}
