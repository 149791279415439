.product_bg {
    background: url("./sticker/bes-bg.png");
    background-size: cover;
    background-position: center;
    /* padding: 150px 175px 154px 176px; */
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
}

.product {
    display: flex;
    justify-content: center;
    /* margin-top: 10vh; */
    padding-top: 20rem;
}

.product_bg h2 {
    line-height: 1.2;
    letter-spacing: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 5rem;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    padding: 1rem 0;
    /* margin: 0 29px 49px; */
    height: 3rem;
    color: var(--bright-orange);
}
.discription {
    /* margin: 49px 7px 34px 12px; */
    /* padding-bottom: 50rem; */
    padding-top: 5rem;
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: var(--bright-orange);
}

.product_number {
    position: absolute;
    background-image: url("./sticker/number_sticker.png");
    background-size: cover;
    text-align: center;
    top: -3rem;
    /* left: -50px; */
    z-index: 999;
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    font-weight: 800;
}

.box {
    display: flex;
    padding: 0.5rem 1rem;
    border: solid 3px var(--dark-maroon);
    background-color: #fff;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: auto;
    position: absolute;
    right: 5%;
    bottom: -7%;
}

.box p {
    font-size: 1.2rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-three);
}

@media screen and (max-width: 1600px) {
    .product_bg {
        padding-bottom: 5rem;
    }
    .product {
        padding-top: 2rem;
    }
    .product img {
        width: 25vw;
    }
    .product_bg h2 {
        font-size: 2.2rem;
    }
    .discription {
        padding-top: 1rem;
        font-size: 1rem;
    }
    .box {
        padding: 0;
    }
    .box p {
        font-size: 1rem;
    }
    .box img {
        width: 2rem;
        height: 2rem;
    }
}

@media screen and (max-width:1000px ) {
       .box p {
        font-size: 0.8rem;
    }
    .box img {
        width: 1.5rem;
        height: 1.5rem;
    }
}
