.brand_bg {
    background-color: var(--cerulean);
    position: relative;
    /* height: 80vh; */
    padding-bottom: 30rem;
}
.brand {
    margin-top: 1.5rem;
}
.brand_detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* width: 50vw; */
    margin: auto;
    z-index: 20;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    /* scale: 120%; */
}

.line {
    background-color: black;
    height: 1rem;
    grid-column: 1/3;
    /* grid-column: ; */
}
.brand {
    margin: 0 auto;
    width: 50%;
}
.brand_bg h2 {
    padding-top: 6rem;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 4.5px;
    color: var(--sun-yellow);
}
.brand_bg .info {
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    margin: 0 auto 3rem;
    width: 70%;
    color: var(--sun-yellow);
}
.details {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* width: 70%; */
    /* margin: 0 auto ; */
}

.details .left {
    background: url("./img/collaboration.png") center no-repeat;
    background-size: cover;
    /* height: 35rem; */
    /* height: 45rem; */
}

.details .right {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 1.5rem;
    text-align: left;
    /* align-items: flex-start; */
    align-items: flex-start;
    justify-content: center;
    /* place-items: center; */
}

.details .right p {
    text-align: justify;
    color: var(--black-three);
    line-height: 1.88;
    font-size: 1rem;
    font-weight: 300;
}

.details .right h3 {
    color: var(--black-three);
    line-height: 2.4;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 800;
}

/*  CSS for book */

.book {
    position: absolute;
    left: 0;
    bottom: 10rem;
}
.book::after {
    content: url("./img//book-2.png");
}
.book_2 {
    position: absolute;
    left: 20rem;
    bottom: 10rem;
    z-index: 1;
}
.flower {
    position: absolute;
    right: 0;
    bottom: 12rem;
}
.calender {
    position: absolute;
    right: 0;
    bottom: 12rem;
}
.white_form {
    position: absolute;
    left: 15rem;
    top: 5rem;
}
.name_in {
    position: absolute;
    right: 5rem;
    top: 5rem;
}
.plan {
    position: absolute;
    right: 1rem;
    top: 7rem;
}

@media screen and (max-width: 1600px) {
    .brand_bg h2 {
        font-size: 2rem;
        padding: 0.5rem 0 0 0;
    }
    .brand_bg .info {
        font-size: 1rem;
    }
    .details .right {
        padding: 1rem;
    }
    .details .right p {
        font-size: 0.8rem;
    }
    .details .right h3 {
        font-size: 1.2rem;
        line-height: 2;
    }
    .name_in img {
        width: 5rem;
    }
    .flower {
        bottom: 12rem;
        right: 1rem;
    }
    .flower img {
        width: 12rem;
    }
    .calender {
        bottom: 6rem;
    }
    .calender img {
        width: 14rem;
    }
}

@media screen and (max-width: 1300px) {
    .brand_detail {
        width: 80%;
        margin: auto;
    }
    .details .right {
        padding: 1rem;
    }
    .details .right p {
        font-size: 0.8rem;
    }
    .book,
    .flower,
    .calender,
    .white_form,
    .name_in,
    .plan {
        display: none;
    }
}
